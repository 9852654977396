/*------- Fold CSS - Contents -------

1. reset.CSS
2. Navbar Styling
3. Hero Section
4. Flex features 1
5. Flex features 2
6. Pricing section
7. Background reviews
8. CTA Section and footer
9. Media Queries

----*/



/*----- 1. Reset.css -----*/

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/* --- Common Styles ---*/

h1, h2, h3, h4, h5, h6 {
	font-family: 'Montserrat';
  font-size: 16px;
}

p {
	font-family: 'Montserrat';
	font-size: 14px;
}

/*----- Helper Classes -----*/

html * {
	text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/*
::-webkit-scrollbar {
	display: none;
}*/

::-moz-selection {
    color: #FFFFFF;
    background: #23D3D3;
}

::selection {
    color: #FFFFFF;
    background: #23D3D3;
}

.container-m {
	max-width: 1024px;
	margin: 0 auto !important;
	padding-left: 40px;
	padding-right: 40px;
	position: relative;
}

.container-s {
	max-width: 920px;
	margin: 0 auto !important;
	padding-left: 40px;
	padding-right: 40px;
	position: relative;
}

#loading {
   width: 100%;
   height: 100%;
   top: 0px;
   left: 0px;
   position: fixed;
   opacity: 1;
   background-color: #F2F2F2;
   z-index: 9999;
   text-align: center;
}

#loading-image {
	display: inline;
  top: 40%;
  position: relative;
  z-index: 9999;
}

.logo {
	position: fixed;
	top: 3%;
	left: 5%;
	z-index: 11;
}

.logo .tld {
	background: #23D3D3;
}

.tld {
	width: 40px;
	height: 40px;
	background: #1e266d;
	color: #FFFFFF;
	border-radius: 50%;
	display: table;
}

.tld2 {
	background: #23D3D3;
}

.tld-text {
	display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.tld-text h6 {
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 700;
	color: #FFFFFF !important;
	text-decoration: none !important;
}


.tld-text a {
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF !important;
	text-decoration: none !important;
}


.tld-text a::after {
	display: none;
}


/*------ Navbar Styling ------*/

.navbar {
  font-family: "Montserrat";
	padding: 15px 0;
	background-color: #FFFFFF !important;
	border-bottom: 1px solid #EFEFF1;
	-webkit-transition: 0.5s all ease;
	transition: 0.5s all ease;
}

.navbar .navbar-brand {
	font-family: 'Montserrat';
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 3px;
	color: #333347 !important;
	vertical-align: middle;
}

.navbar .navbar-brand img {
	vertical-align: middle;
	margin-right: 0.3em;
}

.navbar .navbar-toggler {
	border: none;
}

.navbar span.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar span.navbar-toggler-icon::before {
	outline: none;
}

.navbar .navbar-toggler:focus {
	outline: none;
}

.navbar-nav {
	height: auto;
	background: #FFFFFF;
	padding: 0 1.5rem;
	display: flex;
	align-items: center;
	border-left: 1px solid #F3F3F5;
	-webkit-transition: 0.5s all ease; /* For Safari 3.1 to 6.0 */
	transition: 0.5s all ease;
}

.navbar-nav .nav-item {
	margin-right: 0;
	margin-top: 1.5rem;
	font-size: 0.85rem;
	font-weight: 400;
  text-transform: capitalize;
  color: #333347;
	display: flex;
}

.navbar-nav .nav-item .nav-link {
  color: #333347;
	font-weight: 500;
	display: flex;
	vertical-align: middle;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #333347;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.navbar-nav .nav-item .nav-link:focus {
  color: #333347;
}

.btn-nav {
	margin: 0 !important;
  border-radius: 50px !important;
}

.btn-nav:hover {
	color: #FFFFFF;
	background: #1e266d;
}

.btn-nav:focus, .btn-nav:active {
	background: #1e266d;
	color: #FFFFFF;
}

.btn-alt-nav {
	background: #23D3D3 !important;
  border: 1px solid #23D3D3 !important;
	margin: 0 !important;
}

.btn-alt-nav:hover {
	color: #1e266d !important;
}


/*------ nav Transparent Styling ------*/

.nav-white {
	background-color: transparent !important;
	border-bottom: 1px solid transparent;
	padding: 20px 0;
}

.nav-white span.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(35, 211, 211, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.nav-white .navbar-nav {
	background: transparent !important;
	border-left: 1px solid transparent;
}

.nav-white .navbar-brand {
	color: #FFFFFF !important;
}

.nav-white .nav-item .nav-link {
	color: #FFFFFF;
}

.nav-white .nav-item .nav-link:hover {
  color: #23D3D3;
}

.nav-white .nav-item .nav-link:focus {
  color: #23D3D3;
}

@media only screen and (max-width: 767px) {

	.navbar {
		padding: 15px;
	}

	.navbar .navbar-nav {
		background: #FFFFFF !important;
		padding-bottom: 50px;
	}

 .navbar-nav .nav-item {
		display: block;
	}

	.nav-white .nav-item .nav-link {
		color: #4957B8;
	}

	.navbar .btn-cta {
		padding: 0 30px;
	}

}

@media only screen and (min-width: 240px) {

.navbar.past-main {
	background: #FFFFFF !important;
	border-bottom: 1px solid #EFEFF1;
}

.navbar.effect-main {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar.past-main .navbar-brand {
	color: #333347 !important;
}

.nav-white.past-main .nav-item .nav-link {
  color: #333347;
	font-weight:500;
}

.navbar.past-main .nav-item .nav-link {
	color: #333347;
	font-weight: 500;
}

.navbar.past-main .nav-item:hover .nav-link {
	color: #23D3D3;
}

.navbar.past-main .navbar-brand {
	color: #333347;
}

.nav-white.past-main span.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.6)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.nav-white.effect-main {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
	}
}

.main {
	width: 100%;
	height: 100%;
}

.hero {
	background: linear-gradient(to right, rgba(243, 243, 245, 0), rgba(243, 243, 245, 0)), url(../images/hero-bg.png) no-repeat center center;
	background-size: cover;
	background-position: left;
}

.hero-content {
	max-width: 600px;
}

.hero-inner {
	padding: 150px 0 100px 0;
}


.hero-content .yd_alert {
	background: linear-gradient(to right,rgba(138,155,166,.1),transparent 60%);
	border-radius: 2px;
	padding: 8px;
	margin-bottom: 30px;
}

.hero-content .yd_alert span {
	background: #1e266d;
	border-radius: 2px;
	margin-right: 5px;
	padding: 3px 5px;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: 600;
}

.hero-content .yd_alert a {
	font-size: 13px;
	font-weight: 500;
	color: #373a3c;
	text-decoration: none;
	line-height: 1;
}

.hero-content .yd_alert a:hover {
	color: rgba(0, 0, 0, .5);
	text-decoration: none;
}

.hero-inner h2 {
	font-size: 28px;
	font-weight: 700;
	color: #373a3c;
	line-height: 1.4;
	margin-bottom: 15px;
}

.hero-inner p {
	font-size: 15px;
	font-weight: 400;
	color: #373a3c;
	line-height: 1.4;
	margin-top: 15px;
}

.btn-action {
	font-family: 'Montserrat';
	display: inline-block;
  height: 42px;
  padding: 0 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 2px;
	margin: 20px 0;
  text-transform: uppercase;
	text-decoration: none !important;
  white-space: nowrap;
  cursor: pointer;
	background-color: transparent;
	background: #1e266d;
  color: #FFFFFF;
  border: 1px solid #1e266d;
  border-radius: 50px;
  box-sizing: border-box;
	-webkit-transition: 0.2s;
	-moz-transition: 0.2s;
	transition: 0.2s;
}

.btn-action:hover {
	color: #23D3D3;
	background: #1e266d;
	border: 1px solid #1e266d !important;
	outline: none !important;
	text-decoration: none;
}

.btn-action:focus, .btn-action:active {
	background: #1e266d;
	color: #FFFFFF;
	border: 1px solid transparent !important;
	outline: none !important;
}

.btn-alt {
	background: #23D3D3;
	color: #040e1a !important;
	border: 1px solid #23D3D3 !important;
}

.btn-alt:hover, .btn-alt:focus {
	background: #23D3D3;
	color: #040e1a;
	border: 1px solid #23D3D3 !important;
	outline: none !important;
	text-decoration: none;
}

.hero2 {
	background: #1e266d;
}

.hero2 .hero-inner {
	padding: 150px 0 100px 0;
}

.hero2 .hero-content {
	display: flex;
	background: transparent;
	max-width: 420px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 25px;
}

.hero2 .hero-content .hero-content-inner {
	margin: auto; /* Important */
}

.hero2 .hero-form {
	background: transparent;
	padding: 0;
}

.hero2 .hero-inner h4 {
	font-size: 14px;
	font-weight: 600;
	color: #23D3D3;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 1.2;
	margin-bottom: 15px;
}

.hero2 .hero-inner h2 {
	font-size: 28px;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 1.4;
	margin-bottom: 15px;
}

.hero2 .hero-inner p {
	font-size: 16px;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 1.8;
	margin-top: 15px;
}

.hero2 .hero-form #contactForm {
	background: #FFFFFF;
	padding: 35px;
	border-radius: 10px;
	max-width: 380px !important;
	margin: 0 auto;
	float: none;
}

.hero2 .hero-form #contactForm label {
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 600;
	color: #1e266d;
	line-height: 1.4;
	margin: 10px 0;
}

.hero2 .hero-form #contactForm input, .hero2 .hero-form #contactForm textarea {
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;
	color: #1e266d;
	border: 0;
	border-radius: 0;
	border-bottom: 2px solid #b4becb;
}

.hero2 .hero-form #contactForm .btn-action {
	margin: 20px 0;
	min-width: 100%;
}


.help-block {
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 400;
	margin-top: 5px;
	color: #FF3333;
	font-style: normal !important;
}

.text-success, .text-danger {
	font-size: 14px;
	font-family: 'Poppins';
}

/*-----------------------------------------------------
-------------- Flex Features Section 2 ----------------
-----------------------------------------------------*/


/*----------- Flex Features ------------*/

.flex-split {
	padding: 100px 0;
}

.flex-intro {
	margin-bottom: 50px;
	text-align: center;
}

.flex-intro h2 {
	font-size: 30px;
	font-weight: 600;
	color: #3a3a47;
	line-height: 1.3;
	margin-bottom: 10px;
}

.flex-intro p {
	font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
	letter-spacing: 0.01em;
  color: #818198;
	max-width: 630px;
	margin: 0 auto;
	margin-top: 15px;
	margin-bottom: 15px;
}


.flex-inner {
	margin: 0 0;
}

.flex-inner .f-image {
	padding: 0 35px;
	margin-bottom: 25px;
}

.flex-inverted .f-image {
	padding: 0 35px;
	margin-top: 25px;
}

.flex-inner .f-text {
	padding: 0 35px;
}

 .flex-inner .f-text {
	-webkit-box-flex:1;
	-ms-flex:1;
	flex:1;
	text-align: left;
}

.flex-inner .f-text h2 {
	font-size: 24px;
	font-weight: 600;
	color: #3a3a47;
	line-height: 1.3;
	margin-top: 20px;
}

.flex-inner .f-text  p {
	font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
	letter-spacing: 0.01em;
  color: #818198;
	margin-top: 15px;
	margin-bottom: 15px;
}


.flex-inner .f-text .left-content a {
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: bold;
	color: #8798ab;
	position: relative;
  display: inline-block;
  text-decoration: none;
}

.flex-inner .f-text .left-content a::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -30%;
	height: 2px;
	width: 0;
	background: #8798ab;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.flex-inner .f-text .left-content a:hover::after {
	width: 80px;
}

.flex-inner .f-text .left-content a:hover {
	text-decoration: none;
}

.flex-inner.flex-inverted .f-image {
	-webkit-box-ordinal-group:2;
	-ms-flex-order:1;
	order:1
}


/*-----------------------------------------------------------
------------------- YD Custom Features Styling ------------------
------------------------------------------------------------*/

.yd_ft_cst {
	width: 100%;
	height: 100%;
	padding: 100px 0;
	background: #0c0c4a;
	background: #fafafa;
}

.ft_cst2 {
	background: #F8F8F9;
}

.yd_ft_cst .split_text {
	display: flex;
	max-width: 600px;
	margin: auto;
	height: 100%;
}

.yd_ft_cst .split_text_inner {
	margin: auto; /* Important */
}

.yd_ft_cst .split_text_inner h4 {
	font-size: 13px !important;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #607d9c;
	line-height: 1.3;
}

.yd_ft_cst .split_text_inner h2 {
	font-size: 28px !important;
	font-weight: 600;
	color: #445555;
	line-height: 1.4;
}

.yd_ft_cst .split_text_inner p {
	font-size: 16px !important;
	font-weight: 400 !important;
	color: rgba(0, 0, 0, 0.5);
	line-height: 1.6;
	margin-top: 20px;
}

.yd_ft_cst .split_text_inner .btn-action {
	margin: 20px 0 0 0;
}

.yd_ft_cst .f-text {
	line-height: normal;
	text-align: center;
	background: #fafafa;
}

.ft_cst2 .f-text {
	line-height: normal;
	text-align: center;
	background: #F8F8F9;
	margin-top: 0;
}

.ft_cst2 .f-image {
	margin-top: 0 !important;
}

.yd_ft_cst .cst_box {
	text-align: center;
	padding: 75px 50px;
	max-width: 320px;
	margin: 0 auto;
	margin-bottom: 0;
	border-radius: 5px;
	background: #1e266d;
	border: 1px solid #ececec;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.yd_ft_cst .cst_box img {
	width: 80px;
	height: 80px;
}

.yd_ft_cst .cst_box h2 {
	font-size: 21px;
	font-weight: 600;
	color: #FaFaFa;
	line-height: 1.6;
	margin-top: 20px;
}

.yd_ft_cst .cst_box p {
	font-size: 14px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.6;
	margin-top: 10px;
}

.yd_ft_cst .f-image {
	margin-top: 50px;
}

.yd_ft_cst ul {
	list-style-type: none;
	margin-top: 10px;
}

.yd_ft_cst ul li {
	display: block;
	font-family: 'Montserrat';
	font-size: 16px;
	line-height: 1.4;
	margin-bottom: 10px;
	color: rgba(0, 0, 0, 0.5);
}


/*----------------------------------------------
------------ Single Feature section ------------
----------------------------------------------*/

.ft_single {
	position: relative;
	padding: 100px 0 100px 0;
	background: #1e266d;
}

.yd_flex {
	display: block;
	text-align: center;
}

.yd_flex_1 {
 	-webkit-box-flex:1;
 	-ms-flex:1;
 	flex:1;
}

.yd_flex_2 {
	-webkit-box-ordinal-group:2;
	-ms-flex-order:1;
	order:1
}

.yd_flex_1 .flex_content {
	max-width: 420px;
	margin: 0 auto;
	margin-bottom: 25px;
}

.yd_flex_1 .flex_content h2 {
	font-size: 28px;
	font-weight: 600;
	line-height: 1.4;
	color: #364655;
	color: #FFFFFF;
	margin-bottom: 15px;
}

.yd_flex_1 .flex_content p {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.6;
	color: rgba(255, 255, 255, 0.7);
	margin-bottom: 5px;
}

.flex_main {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.flex_sub {
	align-items: center;
	flex-basis: calc(50% - 24px);
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 15px 10px;
  position: relative;
	background: rgb(248, 249, 250);
	background: #1e266d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1px;
  display: flex;
	cursor: pointer;
  margin-bottom: 0;
  transition: box-shadow .3s ease-in-out;
}

.flex_sub:hover {
	box-shadow:0 0 20px rgba(0, 0, 0, .1);
	border: 1px solid rgba(255, 255, 255, 0.3);
	background: #0c0c4a;
}

.flex_sub .sub_image {
	margin: 0px 0 20px;
}

.flex_sub .sub_image img {
	width: 60px;
	height: 60px;
}

.flex_sub .sub_text {
	text-align: center;
}

.flex_sub .sub_text h4 {
	font-size: 14px;
	font-weight: 600;
	line-height: 1.2;
	color: rgba(255, 255, 255, 0.9);
	margin-bottom: 5px;
}

.flex_sub .sub_text p {
	font-size: 13px;
	font-weight: 500;
	line-height: 1.4;
	color: rgba(255, 255, 255, 0.7);
	margin: 5px 0;
}


/*------------------------------------------------------
--------------- YD Features block Styling --------------
------------------------------------------------------*/

.yd_ft_block {
	padding: 100px 0;
	background: #1e266d;
}

.block_intro {
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
}

.block_intro h2 {
	font-size: 28px;
	font-weight: 500;
	line-height: 1.2;
	color: #23D3D3;
	color: #FFFFFF;
	margin-bottom: 20px;
}

.block_intro p {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.6;
	color: rgba(255,255,255,0.5);
}

.block_list {
  border: 2px solid rgba(255,255,255,0.3);
  border-radius: 5px;
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin-top: 75px;
  overflow: hidden;
}

.block_list li {
  float: left;
  line-height: 1.25;
  border-right: 2px solid rgba(255,255,255,0.3);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #f5f4ff;
  text-transform: uppercase;
  padding: 25px 15px;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.block_list li:last-child {
  border: none;
}

.block_list li {
  width: 50%;
  padding: 47px 0 37px;
	display: block;
}

.block_list li:nth-child(4), .block_list li:nth-child(2), .block_list li:nth-child(6) {
  border-right: none;
}

.block_list li:nth-child(n+3) {
  border-top: 2px solid rgba(255,255,255,0.3);
}

.block_list li img {
	width: 60px;
	display: block;
	margin: 0 auto 23px;
}

.block_list li span {
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	text-transform: capitalize;
}

.block_list li:hover {
  background: #0c0c4a;
}

/*------------------------------------------------
--------------- Review Card Styling --------------
-------------------------------------------------*/


/*---------- YD Flx2 Section Styling -----------*/

.single_review {
	width: 100%;
	height: 100%;
	padding: 100px 0;
}

.yd_flx2 {
	display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
	line-height: 0;
}

.yd_flx2 > * {
  flex: 1 100%;
}

.flx_1 {
	background: #f3f3f5;
	padding: 0;
}


.flx_2 {
	background-color: #1e266d;
	line-height: normal;
	padding: 50px;
	position: relative;
}

.flx_2 .review-text {
	display: flex;
	margin: auto;
	height: 100%;
}

.flx_2 .review-text .review-text-inner {
	margin: auto;
}

.flx_2 h1 {
	font-size: 21px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.9);
	line-height: 1.4;
	letter-spacing: 0;
	margin-bottom: 20px;
}

.flx_2 h2 {
	font-size: 16px;
	font-weight: 600;
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.4;
	margin-top: 20px;
	margin-bottom: 5px;
}

.flx_2 h6 {
	font-size: 14px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.6);
	line-height: 1.4;
}

.flx_2 a {
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 500;
	color: #FFFFFF;
	position: relative;
	display: inline-block;
	text-decoration: none;
}

.flx_2 a:before {
	content: "";
	position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #FFFFFF;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.flx_2 a:hover:before {
	visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}



/*----------------------------------------------------------
----------------- BG Review Section Styling ----------------
----------------------------------------------------------*/

.yd_rev_bg {
	background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)), url(../images/bg1.jpg) no-repeat center center;
	background-size: cover;
	padding: 20px;
}

.yd_rev_bg .rev_block {
	padding: 30px;
	max-width: 420px;
	background: #FFFFFF;
	overflow: hidden;
}

.yd_rev_bg .rev_img {
	display: block;
}

.yd_rev_bg .rev_img img {
	width: 60px;
	margin-bottom: 20px;
}


.yd_rev_bg .rev_txt h3 {
	font-size: 21px;
	color: #364655;
	font-weight: 600;
	line-height: 1.4;
	margin-bottom: 15px;
}

.yd_rev_bg .rev_txt p {
	font-size: 16px;
	color: #787878;
	font-weight: 400;
	line-height: 1.6;
	margin-bottom: 20px;
}

.yd_rev_bg .rev_txt h6 {
	font-size: 14px;
	color: #888888;
	font-weight: 400;
	line-height: 1.4;
	margin-bottom: 3px;
}



/*-----------------------------------------------------------
------------------- Split Features Styling ------------------
------------------------------------------------------------*/


.split_features {
	width: 100%;
	height: 100%;
	padding: 100px 0;
}

.split_features .yd_flx2 {
	min-height: 500px;
}

.split_features .yd_flx2 .flx_1 {
	line-height: normal;
	background: #eee;
	padding: 0 75px;
	min-height: 420px;
}

.split_features .yd_flx2 .flx_1 .split_text {
	display: flex;
	margin: auto;
	height: 100%;
}

.split_features .yd_flx2 .flx_1 .split_text_inner {
	margin: auto; /* Important */
}


.split_features .yd_flx2 .flx_1 h2 {
	font-family: 'Roboto' !important;
	font-size: 32px;
	font-weight: 300;
	color: #111111;
	line-height: 1.4;
	margin-bottom: 15px;
}

.split_features .yd_flx2 .flx_1 p {
	font-family: 'Roboto' !important;
	font-size: 17px;
	font-weight: 400;
	color: #777;
	line-height: 1.6;
	margin-top: 15px;
}

.split_features .yd_flx2 .flx_2 {
	padding: 0;
	background-size: cover;
	min-height: 300px;

}


/*----------------------------------------------------------
----------------- YD Pricing Section Two -------------------
-----------------------------------------------------------*/


.yd_prc2 {
	width: 100%;
	height: 100%;
	background: #FFFFFF;
	padding: 100px 0;
}

.yd_prc2 .pricing-intro {
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 75px;
	text-align: center;
}

 .pricing-intro h1 {
 	font-size: 30px;
 	color: #364655;
 	font-weight: 600;
 	line-height: 1.4;
 	margin-bottom: 15px;
 }

 .pricing-intro p {
 	font-size: 16px;
 	font-weight: 500;
 	line-height: 1.6;
 	letter-spacing: 0.01em;
 	color: #8B92AB;
 	margin: 10px 0;
 }


.yd_prc2 .yd_flx2 .flx_2 {
	padding: 50px 30px;
	text-align: center;
	border-radius: 0 0 20px 20px;
}

.yd_prc2 .yd_flx2 .flx_1 {
	line-height: normal;
	padding: 50px 30px;
	background: #fafafa;
	text-align: center;
	border-radius: 20px 20px 0 0;
}

.yd_prc2 .yd_flx2 .btn-action {
	margin-top: 25px;
}

.yd_prc2 .yd_flx2 .split_text {
	display: flex;
	margin: auto;
	height: 100%;
}

.yd_prc2 .yd_flx2  .split_text_inner {
	margin: auto;
}

.yd_prc2 .yd_flx2 h2 {
	font-size: 26px;
	font-weight: 600;
	color: rgb(54, 70, 85);
	margin: 0;
}

.yd_prc2 .yd_flx2 span {
	font-family: 'Montserrat';
	font-size: 36px;
	font-weight: 600;
	display: inline-block;
	color: #97a6b5;
	margin: 15px 0 0 0;
}

.sub_span {
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 600;
	color: #97a6b5;
	margin: 10px 0;
}


.yd_prc2 .prc_features {
	list-style-type: none;
	margin-top: 25px;
	text-align: left;
}

.yd_prc2 .prc_features li span {
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 500;
	color: #97a6b5;
	vertical-align: middle;
	line-height: 1.4;
	letter-spacing: 0;
	margin: 0 0 10px 0;
}

.yd_prc2 .flx_2 h2, .yd_prc2 .flx_2 span, .yd_prc2 .flx_2 .prc_features li {
	color: #97a6b5;
	color: rgba(255, 255, 255, 0.7);
}

.yd_prc2 .flx_2 span {
	color: #23D3D3;
}


.yd_prc2 .prc_features li img {
	vertical-align: top;
	margin-right: 3px;
}

.yd_prc2 .yd_flx2 a:hover::before {
	display: none !important;
}

/*---------------- YD CTA Box Styling --------------------*/

	.yd_cta_box {
		padding: 0;
		text-align: center;
		background: #1e266d;
	}

	.cta_box {
		background: #1e266d;
		border-radius: 0;
		display: flex;
		margin: auto;
		height: 420px;
		max-width: 800px;

	}

	.cta_box .cta_box_inner {
		margin: auto;
		padding: 0 20px;
	}

	.cta_box .cta_box_inner .box_txt {
		padding: 0;
	}

	.cta_box .cta_box_inner h4 {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.2;
		color: rgba(255, 255, 255, 0.8);
		margin-bottom: 20px;
	}

	.cta_box .cta_box_inner h2 {
		font-size: 24px;
		font-weight: 400;
		line-height: 1.6;
		color: #FFFFFF;
	}

	.cta_box .cta_box_inner .btn-action {
		margin: 30px 0 0 0;
	}


/* ------------ Bact-to-Top Styling Starts Here ------------*/


.footer {
	background: #040E1A;
	background: #1e266d;
	padding: 35px 0;
	border-top: 2px solid #2e2d72;
}

.footer-inner {
	background: #1e266d;
}

.footer .footer-logo {
	text-align: center;
}

.footer .footer-logo h2 {
	font-size: 18px;
	font-weight: 500;
	color: #e5e5e5;
	text-transform: uppercase;
	line-height: 1.4;
	letter-spacing: 1px;
}

.footer p {
	font-size: 24px;
	font-weight: 700;
	color: #e5e5e5;
	line-height: 1.4;
	margin-top: 0;
}

/* ------------ Bact-to-Top Styling Starts Here ------------*/
/*

.bk-top {
	z-index: 90;
	width: 50px;
	height: 75px;
	background: #6d48e5;
	color: #f2f2f0;
	display: table;
	box-shadow:0 5px 15px rgba(0, 0, 0, .1);
}

.bk-top-txt {
	display: table-cell;
  vertical-align: middle;
  text-align: center;
}
*/
.back-to-top {
	position: fixed;
	bottom: 30px;
	right: 30px;
	font-family: 'Montserrat';
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	color: #333333;
	line-height: 1.8;
	display: inline-block;
	padding: 1.5rem 1.2rem;
	margin-top: 0;
	border-radius: 2px;
	background: url(../icons/arrow.svg) center top 1.2rem no-repeat #23D3D3;
	text-decoration: none;
	-webkit-transition: 200ms;
	-moz-transition: 200ms;
	-o-transition: 200ms;
	transition: 200ms;
}

.back-to-top:hover {
  color: #333333;
	text-decoration: none;
}


.footer ul {
	list-style-type: none;
	text-align: center;
	margin-top: 0;
}

.footer .footer-menu {
	text-align: center;
	margin: 20px 0;
}

.footer .footer-links ul {
	text-align: center;
}

.footer ul li {
	font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
	letter-spacing: 0;
  display: inline-block;
	margin-left: 10px;
  margin-right: 10px;
}

.footer ul li a {
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-transform: uppercase;
  position: relative;
  display: inline-block;
  color: #FFFFFF;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: none;
  color: #97a6b5;
}

.footer .footer-links ul li {
	margin-left: 10px;
  margin-right: 10px;
}

.footer .footer-links ul li a img {
	width: 24px;
	vertical-align: middle;
}




/*---------- Split Coming Soon Demo Styling -----------*/


.split_home {
	width: 100%;
	height: 100%;
}

.split_home .yd_flx2 {
	display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
	line-height: 0;
	min-height: 100vh;
}

.split_home .yd_flx2 > * {
  flex: 1 100%;
}

.split_home .flx_1 {
	background: #1E3B70;
	background: #dbd9cd;
	background: #1e266d;
	line-height: normal;
	order: 1;
	min-height: 720px;
	text-align: center;
}

.split_home .flx_2 {
	background: #23D3D3;
	min-height: 720px;
	order: 2;
        padding: 0 !important;
}

.split_home .flx_1 .section-txt {
	display: flex;
	max-width: 600px;
	margin: auto;
	height: 100%;
}

.split_home .flx_1 .section-txt .section-txt-inner {
	margin: auto; /* Important */
}

.split_home .flx_1 .section-txt {
	padding: 50px 50px;
}


.split_home .flx_1 h2 {
	font-family: 'Josefin Sans';
	font-size: 40px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.95);
	line-height: 1.2;
	letter-spacing: 0;
	margin-bottom: 20px;
}

.split_home .flx_1 h4 {
	font-size: 14px;
	font-weight: 500;
	color: #000000;
	line-height: 1.4;
	letter-spacing: 2px;
	margin-bottom: 15px;
}

.split_home .flx_1 p {
	font-size: 16px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.6;
	max-width: 360px;
	margin: 0 auto;
}

.split_home .flx_1 a {
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 500;
	color: #23a393;
	position: relative;
	display: inline-block;
	text-decoration: none;
}

.split_home .btm_block {
	position: relative;
	width: 100%;
	height: auto;
}

.split_home .block_inner {
	position: absolute;
	bottom: 0;
	left: 50px;
}

.split_home .block_txt {
	margin: 0 auto;
}

.split_home .block_txt p {
	font-size: 14px;
	font-weight: 500;
	color: #8f2c34;
}



@media only screen and (min-width: 767px) {


	.split_home .flx_1 {
		flex: 1.1 0 0;
		order: 1;
		flex-basis: 50%;
		transition: 0.3s ease-in;
	}

	.split_home .flx_2 {
		flex: 1 0 0;
		order: 2;
		flex-basis: 50%;
		transition: 0.3s ease-out;
	}
}


.split_footer {
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

.split_footer p {
	font-size: 12px !important;
}

.split_footer ul {
	list-style-type: none;
	text-align: center;
	margin-bottom: 20px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}


.split_footer ul li {
	font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
	letter-spacing: 0;
  display: inline-block;
	margin-left: 10px;
  margin-right: 10px;
}

.split_footer ul li a {
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-transform: uppercase;
  position: relative;
  display: inline-block;
  color: #FFFFFF;
  text-decoration: none;
}

.split_footer ul li a:hover {
  text-decoration: none;
  color: #97a6b5;
}

.split_footer ul li {
	margin-left: 8px;
  margin-right: 8px;
}

.split_footer ul li a img {
	width: 21px;
	vertical-align: middle;
}

ul#countdown {
	list-style: none;
	margin: 20px 0;
	padding: 0;
	display: inline-block;
	text-align: center;
}

ul#countdown li {
	display: inline-block;
}

@media only screen and (max-width: 767px) {

	ul#countdown li span {
		font-size: 34px;
	}

.split_home .btn-alt {
	display: block;
}
}


/*-------- Center Circle Demo  -------- */

.prk-center {
	position: relative;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
	display: table;
}

.prk-circle-box {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.prk-circle {
	margin: 0 auto;
	width: 270px;
	height: 270px;
	border-radius: 50%;
	background: #1e266d;
	box-shadow: 0 3px 50px 0 rgba(255, 94, 58, 0.1);
	color: #e84545;
	transition: all 400ms ease-in;
}

.prk-text {
	position: relative;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
	display: table;
}

.txt-box {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}


.txt-box ul#countdown li span {
	font-family: Arial;
	font-size: 24px;
	font-weight: 600;
	line-height: 1;
	color: #23D3D3;
	padding: 6px;
	transition: all 400ms ease-in;
}

.txt-box ul#countdown li.seperator {
	font-size: 21px;
	line-height: 1;
	vertical-align: top;
	color: #23D3D3;
	transition: all 400ms ease-in;
}

.txt-box ul#countdown li p {
	color: rgba(255, 255, 255, 0.7);
	font-size: 11px;
	font-weight: 500;
	padding-top: 15px;
	transition: all 400ms ease-in;
}

.txt-box h2 {
	font-size: 16px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.8);
	margin-bottom: 0.5em;
	transition: all 400ms ease-in;
}

.txt-box h2 span {
	color: #23D3D3;
	font-weight: 700;
}

.txt-box h3 {
	font-size: 16px;
	font-weight: 500;
	color: #e84545;
	margin-top: 0.5em;
}

.txt-box a {
	color: #6142d2;
}

.txt-box a:hover {
	color: #6142d2;
}


.chimp-form .mail {
	position: relative;
	background-color: #F9F9F9;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  transition: all .3s;
}

.form {
	padding-top: 25px;
	position: relative;
	text-align: none;
}

.form input {
  color: #222222;
  font-family: 'Montserrat';
  padding: 0;
  font-size: 13px;
	font-weight: 500;
	height: 45px;
  border: 0;
  border-radius: 30px;
	background-color: #F3F3F3;
  outline: none;
  padding: 0 100px 0 20px;
	box-shadow: 0 5px 30px rgba(255, 255, 255, 0.1);
}

.form input:focus {
	outline: none !important;
	border-color: transparent;
}

.form .submit-button {
  height: 45px;
  border: 0;
  border-radius: 30px;
  margin-left: -35px;
  padding: 0 25px 0 25px;
	background: #23D3D3;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
	letter-spacing: 0;
  color: #FFFFFF;
	cursor: pointer;
  outline: none;
	box-shadow: 0 5px 30px rgba(255, 255, 255, 0.05);
	-webkit-transition: 500ms;
	-moz-transition: 500ms;
	transition: 500ms;
}

#email-error {
	position: absolute;
	left: 20%;
	right: 0;
	bottom: 0%;
	font-family: 'Montserrat';
	font-size: 13px;
	font-weight: 500;
	color: #FF3333;
}

#response {
	position: absolute;
	left: 20%;
	bottom: -90%;
	vertical-align: middle;
	font-family: 'Montserrat';
	font-size: 13px !important;
	font-weight: 500 !important;
	margin: 0 !important;
	color: #15ccbe;
}

#response h4 {
	font-size: 13px !important;
	font-weight: 500 !important;
	text-align: center;
}

#chimp-email-error {
	position: absolute;
	left: 15%;
	bottom: -50%;
	vertical-align: middle;
	text-align: center;
	font-family: 'Montserrat';
	font-size: 13px;
	font-weight: 500;
	color: #23D3D3;
}

.yd_cta_box #chimp-email-error {
	position: absolute;
	left: 25%;
	bottom: -50%;
}


.yd_cta_box #response {
	position: absolute;
	left: 25%;
	bottom: -90%;
}

.success-message {
	color:#33cc33;
	margin-top: 5px;
}

.form-note p {
	font-size: 12px;
}



/*------------------------------------------
-------------- Media Queries ---------------
------------------------------------------*/

@media only screen and (min-width: 767px) {

	.logo {
		position: fixed;
		top: 5%;
		left: 2%;
	}


	.navbar-nav {
			margin-top: 0;
		}

	.navbar-nav .nav-item {
		margin-top: 0;
		margin-right: 15px;
	  font-size: 0.85rem;
	  font-weight: 400;
	}

	.container-s {
		padding-left: 20px;
		padding-right: 20px;
	}

	.container-m {
		padding-left: 20px;
		padding-right: 20px;
	}

	.hero-inner {
		padding: 200px 0 150px 0;
	}

	.hero2 .hero-form #contactForm {
		float: none;
	}

	.hero2 .hero-inner {
		padding: 100px 0 100px 0;
	}

	.hero-inner h2 {
		font-size: 34px;
	}

	.hero2 .hero-content {
		text-align: left;
		margin-bottom: 0;
	}

	.hero2 .hero-inner h2 {
		font-size: 34px;
	}

	.flex-inner {
		position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
		margin: 0 0;
	}

	.flex-inner .f-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 420px;
    flex: 0 0 420px;
	}

	.flex-inner .f-text h2 {
		font-size: 24px;
	}

	.flex-inner .f-text  p {
		font-size: 16px;
		font-weight: 500;
	}

	.yd_flex {
		position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
		text-align: left;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	}

	.yd_flex .yd_flex_2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 568px;
    flex: 0 0 568px;
	}

	.yd_flex_1 .flex_content {
		max-width: 420px;
		margin: 0;
	}

	.yd_flex_1 .flex_content h2 {
		font-size: 30px;
	}


	.flex_main {
		display: flex;
	}

	.flex_sub {
	  margin: 0;
	  padding: 32px;
	}

	.flex_sub .sub_text h4 {
		font-size: 16px;
	}

	.flx_1 {
		flex: 1 0 0;
	}

	.flx_2 {
		flex: 1 0 0;
	}


	.split_features .yd_flx2 .flx_1 {
		flex: 1 0 0;
		flex-basis: 50%;
		padding: 0 100px;
	}

	.split_features .yd_flx2 .flx_2 {
		flex: 1 0 0;
		flex-basis: 50%;
	}

	.split_features .yd_flx2 .flx_1 h2 {
		font-size: 38px;
	}

	.yd_prc2 .yd_flx2 .flx_1 {
		flex: 1 0 0;
		flex-basis: 50%;
		padding: 75px;
		border-radius: 10px 0 0 10px;
	}

	.yd_prc2 .yd_flx2 .flx_2 {
		flex: 1 0 0;
		flex-basis: 50%;
		padding: 75px;
		border-radius: 0 10px 10px 0;
	}

	.yd_ft_cst .f-image {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 360px;
		flex: 0 0 360px;
	}

	.yd_ft_cst .split_text_inner h2 {
		font-size: 30px !important;
	}

	.yd_ft_cst .split_text_inner p {
		font-size: 17px !important;
	}

	.yd_ft_cst .cst_box {
		margin-bottom: 0;
	}

	.block_list li {
	  width: 25%;
	  padding: 47px 0 37px;
		display: block;
	}

	.block_list li:nth-child(4) {
	  border-right: none;
	}

	.block_list li:nth-child(2), .block_list li:nth-child(6) {
		border-right: 2px solid rgba(255,255,255,0.3);
 }

	.block_list li:nth-child(n+5) {
	  border-top: 2px solid rgba(255,255,255,0.3) !important;
	}

	.block_list li:nth-child(n+3) {
	  border-top: none;
	}

	.yd_cta_box {
		padding: 0 0;
	}

	.cta_box .cta_box_inner {
		padding: 0 50px;
	}

	.cta_box .cta_box_inner h2 {
		font-size: 28px;
		line-height: 1.6;
	}

	.yd_rev_bg {
		padding: 100px;
	}

	.yd_rev_bg .rev_block {
		padding: 60px;
	}

	.footer .footer-logo {
		text-align: left;
	}

	.footer .footer-menu {
		text-align: right;
		margin: 0;
	}

	.footer .footer-links ul {
		text-align: right;
	}

}

@media only screen and (min-width: 480px) {

	.container-s {
		padding-left: 40px;
		padding-right: 40px;
	}

	.flex-inner .f-text {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: left;
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

	.navbar-nav {
		padding: 0 0.5rem;
	}

	.yd_flex .yd_flex_2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 420px;
		flex: 0 0 420px;
	}
}


@media only screen and (min-width: 1025px) {

	.hero2 .hero-form #contactForm {
		float: right;
	}

.flex-inner .f-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 500px;
    flex: 0 0 500px;
	}

	.yd_ft_cst .f-image {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 540px;
			flex: 0 0 540px;
	}

}


@media only screen and (min-width: 240px)and (max-width: 420px) {
.form input {
    padding: 0 75px 0 20px;
}
.form .submit-button {
    margin-left: 0;
    margin-top: 10px;
	}
}

@media only screen and (min-width: 24em) {

	.prk-circle {
		width: 360px;
		height: 360px;
	}

	.txt-box h2 {
		font-size: 21px;
		font-weight: 500;
	}

	.txt-box .btn-action {
		padding: 10px 24px;
		font-size: 12px;
		margin: 25px 10px 0 0;
	}

	.txt-box ul#countdown li span {
		font-size: 38px;
	}

	.txt-box ul#countdown li.seperator {
		font-size: 32px;
	}

	.txt-box ul#countdown li p {
		font-size: 14px;
	}
}


@media only screen and (max-width: 420px) {

	.form {
		text-align: center;
	}

	.form input {
	  padding: 0 75px 0 20px;
	}

	.form .submit-button {
	  margin-left: 0;
		margin-top: 10px;
	}

	#chimp-email-error {
	    left: 5%;
	    bottom: -30%;
	}

        .yd_cta_box #response {
            position: absolute;
	   left: 5%;
	   bottom: -50%;
       }

	.yd_cta_box #chimp-email-error {
		left: 15%;
		bottom: -25%;
	}

}
